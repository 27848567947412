import * as React from "react"
import { useState } from "react"
import styled from "styled-components"
import DatePicker from "react-datepicker"
import * as colors from "../../constants/colors"
import { Icon } from "semantic-ui-react"
import { checkIsDeliveryEnabled } from "../../util/dateUtil"
import BasketItems from "./BasketItems"
import { getExpectedDeliveryDate, getProductMeta, isVendorPaused } from "../../util/productUtil"
import { rouge } from "../../../constant/colors"
import { getBankHolidays } from "../../api/bankHolidaysApi"
import { getSoonestDeliveryDate } from "@social-supermarket/social-supermarket-model"
import {
  BasketItemType,
  ProductType,
  VariantType,
  VendorType,
} from "@social-supermarket/social-supermarket-model"

const BLOCKED_DATES = ["2023-10-04", "2023-10-05"]

const Container = styled.div`
  border: 1px solid ${colors.borderGrey};
  padding: 10px;
  margin-bottom: 10px;
`
const VendorInfo = styled.div`
  font-size: 0.8em;
  margin-bottom: 20px;
`
const DateError = styled.p`
  color: ${rouge};
`
const VendorInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
`
const VendorDeliveryNotice = styled.div`
  color: ${rouge};
`
const DateContainer = styled.div``
const ProductDetails = styled.div``
const ArrivesBefore = styled.div``
const GiftNote = styled.div``

interface Props {
  vendor: VendorType
  dateError: string
  resetDateError: () => void
  items: BasketItemType[]
  updateProduct: (
    product: ProductType,
    variant: VariantType,
    basketItemKey: string,
    quantity: number,
    meta: object,
    refresh: boolean
  ) => void
}

const VendorBasketItems = ({ vendor, dateError, resetDateError, items, updateProduct }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date>(
    getSoonestDeliveryDate([...BLOCKED_DATES], items)
  )
  const [isHolidaysLoaded, setIsHolidaysLoaded] = useState<boolean>(false)
  const [bankHolidays, setBankHolidays] = useState<string[]>([])
  const isSocialSupermarketHampersVendor = vendor.slug === "social-supermarket-hampers"

  async function updateDeliveryDate() {
    if (!selectedDate || !isSocialSupermarketHampersVendor) return
    resetDateError()
    let index = items.length

    for (const item of items) {
      const { product, key, variant, quantity } = item
      await updateProduct(
        product,
        variant,
        key,
        Number(quantity),
        {
          ...getProductMeta(product),
          deliveryDate: selectedDate,
        },
        --index === 0
      )
    }
  }

  const checkDefaultSelectedDate = (): void => {
    getBankHolidays().then(res => {
      const bankHolidays = res["england-and-wales"].events.map(({ date }) => date)
      setBankHolidays([...bankHolidays, ...BLOCKED_DATES])
      const soonestDate = getSoonestDeliveryDate(bankHolidays, items)
      const itemsDeliveryDate = items[0].deliveryDate && new Date(items[0].deliveryDate)
      const isDeliveryDateBeforeSoonestPossibleDate =
        !itemsDeliveryDate ||
        soonestDate.setHours(0, 0, 0, 0) > itemsDeliveryDate.setHours(0, 0, 0, 0)
      const correctedDate = isDeliveryDateBeforeSoonestPossibleDate
        ? soonestDate
        : itemsDeliveryDate
      setSelectedDate(correctedDate)
      setIsHolidaysLoaded(true)
    })
  }

  const expectedDeliveryDate: string = getExpectedDeliveryDate(vendor)

  const handleCalendarClose = (): void => {
    if (
      selectedDate &&
      (items[0].deliveryDate ||
        new Date(items[0].deliveryDate).getTime() !== selectedDate.getTime())
    ) {
      updateDeliveryDate()
    }
  }

  React.useEffect(() => {
    if (isSocialSupermarketHampersVendor) {
      checkDefaultSelectedDate()
    }
  }, [])

  React.useEffect(() => {
    if (isHolidaysLoaded) {
      updateDeliveryDate()
    }
  }, [isHolidaysLoaded])

  return (
    <Container>
      <VendorInfo>
        <VendorInfoTop>
          {vendor.slug !== "undefined" && (
            <>
              <ProductDetails>
                <Icon name="shipping fast" /> Delivered by <b>{vendor.name}</b>
                {vendor.slug === "social-supermarket-digital" ? null : vendor.acceptsGiftNotes ? (
                  <GiftNote>
                    <Icon name="edit outline" /> Gift note available
                  </GiftNote>
                ) : (
                  <GiftNote>
                    <Icon name="window close outline" /> No gift note available
                  </GiftNote>
                )}
              </ProductDetails>
              <DateContainer>
                <ArrivesBefore>
                  {isSocialSupermarketHampersVendor ? (
                    "Select delivery date:"
                  ) : vendor.slug !== "social-supermarket-digital" ? (
                    <>
                      Estimated arrival by <b>{expectedDeliveryDate}</b>
                    </>
                  ) : null}
                </ArrivesBefore>
                {isSocialSupermarketHampersVendor && (
                  <>
                    <DatePicker
                      selected={selectedDate}
                      onChange={setSelectedDate}
                      onCalendarClose={handleCalendarClose}
                      minDate={getSoonestDeliveryDate(bankHolidays, items)}
                      filterDate={date => checkIsDeliveryEnabled(date, bankHolidays)}
                      style={{ width: "100%" }}
                      dateFormat="dd/MM/yyyy"
                      required
                      popperPlacement="bottom-end"
                    />
                    {dateError && <DateError>{dateError}</DateError>}
                  </>
                )}
              </DateContainer>
            </>
          )}
        </VendorInfoTop>
        {vendor.deliveryNotice && (
          <VendorDeliveryNotice>{vendor.deliveryNotice}</VendorDeliveryNotice>
        )}
        {/*{isVendorPastEventDate(vendor) && !isVendorPaused(vendor) && (*/}
        {/*  <>*/}
        {/*    <div>-</div>*/}
        {/*    <VendorDeliveryNotice>*/}
        {/*      Please be aware this may not arrive in time for Christmas.*/}
        {/*    </VendorDeliveryNotice>*/}
        {/*  </>*/}
        {/*)}*/}

        {isVendorPaused(vendor) && (
          <>
            <div>-</div>
            <VendorDeliveryNotice>
              Please be aware this brand has stopped shipping and will resume on{" "}
              {vendor.resumeShipmentDate}
            </VendorDeliveryNotice>
          </>
        )}
      </VendorInfo>

      <BasketItems items={items} adjustable />
    </Container>
  )
}

export default VendorBasketItems
