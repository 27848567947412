import * as React from "react"
import styled from "styled-components"
import * as colors from "../../constants/colors"
import { Icon } from "semantic-ui-react"
import BasketItems from "./BasketItems"
import {
  getExpectedDeliveryDate,
  isVendorPastEventDate,
  isVendorPaused,
} from "../../util/productUtil"
import { rouge } from "../../../constant/colors"
import { BasketItemType, VendorType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  border: 1px solid ${colors.borderGrey};
  padding: 10px;
  margin-bottom: 10px;
`
const VendorInfo = styled.div`
  font-size: 0.8em;
  margin-bottom: 20px;
`
const DateError = styled.p`
  color: ${rouge};
`
const VendorInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
`
const VendorDeliveryNotice = styled.div`
  color: ${rouge};
`
const DateContainer = styled.div``
const ProductDetails = styled.div``
const ArrivesBefore = styled.div``

interface Props {
  vendor: VendorType
  items: BasketItemType[]
}

const VendorConfirmBasketItems = ({ vendor, items }: Props) => {
  items[0].deliveryDate

  const isSocialSupermarketHampersVendor = vendor.slug === "social-supermarket-hampers"

  const expectedDeliveryDate: string = getExpectedDeliveryDate(vendor)

  return (
    <Container>
      <VendorInfo>
        <VendorInfoTop>
          {vendor.slug !== "undefined" && (
            <>
              <ProductDetails>
                <Icon name="shipping fast" /> Delivered by <b>{vendor.name}</b>
              </ProductDetails>
              <DateContainer>
                <ArrivesBefore>
                  {isSocialSupermarketHampersVendor ? (
                    <>
                      Arrives on <b>{new Date(items[0].deliveryDate).toLocaleDateString()}</b>
                    </>
                  ) : vendor.slug !== "social-supermarket-digital" ? (
                    <>
                      Estimated arrival by <b>{expectedDeliveryDate}</b>
                    </>
                  ) : null}
                </ArrivesBefore>
              </DateContainer>
            </>
          )}
        </VendorInfoTop>
        {vendor.deliveryNotice && (
          <VendorDeliveryNotice>{vendor.deliveryNotice}</VendorDeliveryNotice>
        )}

        {isVendorPastEventDate(vendor) && !isVendorPaused(vendor) && (
          <>
            <div>-</div>
            <VendorDeliveryNotice>
              Please be aware this may not arrive in time for Christmas.
            </VendorDeliveryNotice>
          </>
        )}

        {isVendorPaused(vendor) && (
          <>
            <div>-</div>
            <VendorDeliveryNotice>
              Please be aware this brand has stopped shipping and will resume on{" "}
              {vendor.resumeShipmentDate}
            </VendorDeliveryNotice>
          </>
        )}
      </VendorInfo>

      <BasketItems items={items} smallImage />
    </Container>
  )
}

export default VendorConfirmBasketItems
